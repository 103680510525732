import BenefitsList from "../BenefitsList/BenefitsList";
import Button from "../Button/Button";
import "./WhyWe.css";

const WhyWe = ({ openModal }) => {
  return (
    <div className="why-we relative z-50 row-span-1  col-span-1 max-w-[1920px] w-full mx-auto ">
      <a id="Переваги" className="absolute -top-[120px]"/>
      <div className="why-we-top flex flex-col items-center mb-[50px]">
        <div className="w-full flex flex-col items-center">
          <h2 className="why-we-title text-6xl text-white">ЧОМУ МИ?</h2>
          <p className="why-we-description text-3xl text-white mb-4">
            Наші переваги перед конкурентами
          </p>

          <BenefitsList />
        </div>

        <div className="why-we-bottom-container max-w-[1560px] w-full mt-[75px] relative left-0">
        <h2 className="why-we-bottom-title text-6xl max-w-[1128px]  text-white ">
          ХОЧЕТЕ ПІДІБРАТИ ТА РОЗРАХУВАТИ ВАРТІСТЬ АВТОМОБІЛЯ?
        </h2>
        <p className="max-w-[636px] why-we-bottom-proposition text-3xl text-white mb-[33px] font-normal">
          Зв’яжіться з нами прямо зараз, щоб ми допомогли вам з
          вибором авто яке відповідає вашим вимогам і бюджету
        </p>
        <Button
          onClick={() => openModal()}
          text={"Отримати консультацію"}
          classesDiv="why-button-container "
          classesButton="whywe-button"
        />
      </div>
        
      </div>
      
    </div>
  );
};

export default WhyWe;
