import logo from "../../assets/image/logo-bottom.png";
import local from "../../assets/image/footer/local.png";
import insta from "../../assets/image/footer/instagram.png";
import telegram from "../../assets/image/footer/telegram.png";
import facebook from "../../assets/image/footer/facebook.png";
import email from "../../assets/image/footer/message.png";
import phone from "../../assets/image/footer/phone.png";

import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer flex flex-col relative z-50 ">
      <div className="footer-top footer-desktop flex justify-between">
        <ul className="footer-list items-start">
          <li className="footer-item">
            <a href="https://www.instagram.com/magnumcarss/?igsh=MXd5ZjY2M2ttODhtcQ%3D%3D">
              INSTAGRAM
            </a>
          </li>
          <li className="footer-item">
            <a href="https://t.me/magnumcars">TELEGRAM</a>
          </li>
          <li className="footer-item">
            <a href="https://www.facebook.com/magnumcarss?rdid=SteXLthBOnak9RVp">
              FACEBOOK
            </a>
          </li>
        </ul>
        <ul className="footer-list flex flex-col items-center">
          <li className="footer-item">
            <a href="https://www.google.com/maps/place/%D0%B2%D1%83%D0%BB%D0%B8%D1%86%D1%8F+%D0%93%D0%BE%D1%80%D0%BE%D0%B4%D0%BE%D1%86%D1%8C%D0%BA%D0%B0,+282%D0%B0,+%D0%9B%D1%8C%D0%B2%D1%96%D0%B2,+%D0%9B%D1%8C%D0%B2%D1%96%D0%B2%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+79000/@49.8238147,23.924532,17z/data=!3m1!4b1!4m6!3m5!1s0x473ae73544d00001:0x5ceabbc076412be1!8m2!3d49.8238113!4d23.9271069!16s%2Fg%2F11rg5yq0nn?hl=uk-UK&entry=tts">
              Львів, Городоцька 282а
            </a>
          </li>
          <li className="footer-item">
            <a href="https://www.google.com/maps/place/%D0%9A%D0%B0%D1%80%D0%BD%D0%B5%D0%B3%D0%B8-%D1%86%D0%B5%D0%BD%D1%82%D1%80+%2B+%D0%B1%D0%B0%D1%88%D0%BD%D1%8F/@50.4385244,30.5393885,17z/data=!3m1!4b1!4m6!3m5!1s0x40d4cfa834d199e5:0x68a4f52b9a127c67!8m2!3d50.4385244!4d30.5393885!16s%2Fg%2F11dx8_r2wh?entry=tts">
              Київ, Кловський узвіз 7
            </a>
          </li>
          <li className="footer-item">
            <a href="https://www.google.com/maps/place/101%D0%90,+%D0%9A%D0%B8%D0%B5%D0%B2%D1%81%D0%BA%D0%B0%D1%8F+%D1%83%D0%BB.,+101%D0%B0,+%D0%A0%D1%96%D0%B2%D0%BD%D0%B5,+%D0%A0%D1%96%D0%B2%D0%BD%D0%B5%D0%BD%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+33000/@50.6140796,26.30713,17z/data=!3m1!4b1!4m6!3m5!1s0x472f137fb2e8ebdf:0x7657f30d90ebc138!8m2!3d50.6140796!4d26.30713!16s%2Fg%2F1tdvcbm8?shorturl=1&entry=tts">
              Рівне, Київська 101а
            </a>
          </li>
          <li className="footer-item">
            <a href="https://www.google.com/maps/place/%D0%BF%D1%80%D0%BE%D1%81%D0%BF%D0%B5%D0%BA%D1%82+%D0%93%D0%B0%D0%B3%D0%B0%D1%80%D1%96%D0%BD%D0%B0,+19,+%D0%9E%D0%B4%D0%B5%D1%81%D0%B0,+%D0%9E%D0%B4%D0%B5%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+65000/@46.4552943,30.7471104,17z/data=!3m1!4b1!4m6!3m5!1s0x40c633dbc28d4b4b:0x54340f7c42bd06df!8m2!3d46.4552943!4d30.7471104!16s%2Fg%2F11h4g1g2bn?coh=219816&entry=tts&g_ep=EgoyMDI0MDgxNC4xKgBIAVAD">
              Одеса, Проспект Гагаріна 19Б
            </a>
          </li>
        </ul>
        <ul className="footer-list flex flex-col items-end">
          <li className="footer-item">
            <a href="mailto: support@magnumcarss.com">
              support@magnumcarss.com
            </a>
          </li>
          <li className="footer-item">
            <a href="tel:+380662136202">+(38) 066 213 62 02</a>
          </li>
        </ul>
      </div>

      <div className="footer-top footer-mobile flex justify-between">
        <div className="footer-office-wrap">
          <div className="footer-title-ofice">офіси знаходяться</div>
          <ul className="footer-list flex flex-col items-center">
            <li className="footer-item">
              <img src={local} alt=";local"/>
              <a href="https://www.google.com/maps/place/%D0%B2%D1%83%D0%BB%D0%B8%D1%86%D1%8F+%D0%93%D0%BE%D1%80%D0%BE%D0%B4%D0%BE%D1%86%D1%8C%D0%BA%D0%B0,+282%D0%B0,+%D0%9B%D1%8C%D0%B2%D1%96%D0%B2,+%D0%9B%D1%8C%D0%B2%D1%96%D0%B2%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+79000/@49.8238147,23.924532,17z/data=!3m1!4b1!4m6!3m5!1s0x473ae73544d00001:0x5ceabbc076412be1!8m2!3d49.8238113!4d23.9271069!16s%2Fg%2F11rg5yq0nn?hl=uk-UK&entry=tts">
                Львів, Городоцька 282а
              </a>
            </li>
            <li className="footer-item">
              <img src={local} alt=";local"/>
              <a href="https://www.google.com/maps/place/%D0%9A%D0%B0%D1%80%D0%BD%D0%B5%D0%B3%D0%B8-%D1%86%D0%B5%D0%BD%D1%82%D1%80+%2B+%D0%B1%D0%B0%D1%88%D0%BD%D1%8F/@50.4385244,30.5393885,17z/data=!3m1!4b1!4m6!3m5!1s0x40d4cfa834d199e5:0x68a4f52b9a127c67!8m2!3d50.4385244!4d30.5393885!16s%2Fg%2F11dx8_r2wh?entry=tts">
                Київ, Кловський узвіз 7
              </a>
            </li>
            <li className="footer-item">
              <img src={local} alt=";local"/>
              <a href="https://www.google.com/maps/place/101%D0%90,+%D0%9A%D0%B8%D0%B5%D0%B2%D1%81%D0%BA%D0%B0%D1%8F+%D1%83%D0%BB.,+101%D0%B0,+%D0%A0%D1%96%D0%B2%D0%BD%D0%B5,+%D0%A0%D1%96%D0%B2%D0%BD%D0%B5%D0%BD%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+33000/@50.6140796,26.30713,17z/data=!3m1!4b1!4m6!3m5!1s0x472f137fb2e8ebdf:0x7657f30d90ebc138!8m2!3d50.6140796!4d26.30713!16s%2Fg%2F1tdvcbm8?shorturl=1&entry=tts">
                Рівне, Київська 101а
              </a>
            </li>
            <li className="footer-item">
              <img src={local} alt=";local"/>
              <a href="https://www.google.com/maps/place/%D0%BF%D1%80%D0%BE%D1%81%D0%BF%D0%B5%D0%BA%D1%82+%D0%93%D0%B0%D0%B3%D0%B0%D1%80%D1%96%D0%BD%D0%B0,+19,+%D0%9E%D0%B4%D0%B5%D1%81%D0%B0,+%D0%9E%D0%B4%D0%B5%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+65000/@46.4552943,30.7471104,17z/data=!3m1!4b1!4m6!3m5!1s0x40c633dbc28d4b4b:0x54340f7c42bd06df!8m2!3d46.4552943!4d30.7471104!16s%2Fg%2F11h4g1g2bn?coh=219816&entry=tts&g_ep=EgoyMDI0MDgxNC4xKgBIAVAD">
                Одеса, Проспект Гагаріна 19Б
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-network-wrap">
          <div className="footer-title-network">соціальні мережі</div>
          <ul className="footer-list items-start">
            <li className="footer-item">
              <img src={insta} alt="insta"/>
              <a href="https://www.instagram.com/magnumcarss/?igsh=MXd5ZjY2M2ttODhtcQ%3D%3D">
                INSTAGRAM
              </a>
            </li>
            <li className="footer-item">
              <img src={telegram} alt="telegram"/>
              <a href="https://t.me/magnumcars">TELEGRAM</a>
            </li>
            <li className="footer-item">
              <img src={facebook} alt="facebook" />
              <a href="https://www.facebook.com/magnumcarss?rdid=SteXLthBOnak9RVp">
                FACEBOOK
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-contact-wrap">
          <div className="footer-title-contact">контакти</div>
          <ul className="footer-list flex flex-col items-end">
            <li className="footer-item">
              <img src={email} alt="email" />
              <a href="mailto: support@magnumcarss.com">
                support@magnumcarss.com
              </a>
            </li>
            <li className="footer-item">
              <img src={phone} alt="phone" />
              <a href="tel:+380662136202">+(38) 066 213 62 02</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom px-4">
        <a href="#" className="footer-bottom-logo">
          <img src={logo} alt="logo" className="" />
        </a>
        {/* <div className="footer-policy"> */}
          <a href="https://magnumcarss.com.ua/politika-konfidencialnosti/" className="footer-policy">
            Політика Конфіденційності
          </a>
        {/* </div> */}

        <a
          href="https://newstart-digital.com.ua/#ua"
          className="developers font-[Arimo] text-[30px] text-[#fff]"
          target="_blank"
          rel="noopener noreferrer"
        >
          Development by New Start Digital
        </a>
      </div>
    </footer>
  );
};

export default Footer;
