import video1 from './assets/video/1.mp4';
import video2 from './assets/video/2.mp4';
import video3 from './assets/video/3.mp4';
import video4 from './assets/video/4.mp4';
import video5 from './assets/video/5.mp4';
import video6 from './assets/video/6.mp4';
import video7 from './assets/video/7.mp4';


const videos_array = [
    {
        videoUrl: video1,
        car: "Opel Vectra",
        capacity: "1.9 Дизель",
        years: "2008",
        transmission: "Механічна",
        assignment: "2 967 Є - АВТО для ЗСУ",
    },
    {
        videoUrl: video2,
        car: "Hyundai i30",
        capacity: "1.6 Дизель",
        years: "2010",
        transmission: "Механічна",
        assignment: "4 560 Є - АВТО для ЗСУ",
    },
    {
        videoUrl: video3,
        car: "Audi A4",
        capacity: "2.0 Дизель",
        years: "2015",
        transmission: "Автоматична",
        assignment: "10 400 Є - АВТО ПІД КЛЮЧ",
    },
    {
        videoUrl: video4,
        car: "Mitsubishi Outlander",
        capacity: "2.0 Дизель",
        years: "2008",
        transmission: "Механічна",
        assignment: "5 230 Є - АВТО для ЗСУ",
    },
    {
        videoUrl: video5,
        car: "Volkswagen Touareg",
        capacity: "2.5 Дизель",
        years: "2006",
        transmission: "Механічна",
        assignment: "5 560 Є - АВТО для ЗСУ",
    },
    {
        videoUrl: video6,
        car: "Ford Kuga",
        capacity: "2.0 Дизель",
        years: "2012",
        transmission: "Механічна",
        assignment: "7 900 Є - АВТО ПІД КЛЮЧ",
    },
    {
        videoUrl: video7,
        car: "Ford Escape",
        capacity: "2.0 Бензин",
        years: "2016",
        transmission: "Автоматична",
        assignment: "8 900 Є - АВТО ПІД КЛЮЧ",
    },
]

export {
    videos_array
}