const SendFormService = () => {
    const botToken = '7154640844:AAFcI4CUAVC3cEAYkuyi5YYX9OVRxO46nFo';
    const chatId = '-1002024656503';

    const url = `https://api.telegram.org/bot${botToken}/`;

    const sendForm = async (formData) => {
        const data = {
            chat_id: chatId,
            text: formData
        };

        try {
            const request = await fetch(`${url}sendMessage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!request.ok) {
                // setThenkYou(true);
                // document.body.style.overflow = 'hidden';
            }
            return await request.json();
        } catch (e) {
            console.log(e);
            return Promise.resolve(e);
        }
    };

    const sendFormToRemoteAction = async (values) => {
        let sendData = new FormData();

        if (values !== null && values !== undefined) {
            for (const [key, value] of Object.entries(values)) {
                sendData.append(key, value);
            }
        }

        if (localStorage.getItem('utm_source')) {
            sendData.append('utm_source', localStorage.getItem('utm_source'));
        }

        if (localStorage.getItem('utm_medium')) {
            sendData.append('utm_medium', localStorage.getItem('utm_medium'));
        }

        if (localStorage.getItem('utm_term')) {
            sendData.append('utm_term', localStorage.getItem('utm_term'));
        }

        if (localStorage.getItem('utm_content')) {
            sendData.append('utm_content', localStorage.getItem('utm_content'));
        }

        if (localStorage.getItem('gclid')) {
            sendData.append('gclid', localStorage.getItem('gclid'));
        }

        if (localStorage.getItem('utm_campaign')) {
            sendData.append(
                'utm_campaign',
                localStorage.getItem('utm_campaign')
            );
        }

        try {
            const request = await fetch('order.php', {
                method: 'POST',
                body: sendData
            });

            if (!request.ok) {
            }
            return await request.json();
        } catch (e) {
            console.log(e);
            return Promise.resolve(e);
        }
    };

    return {
        sendForm,
        sendFormToRemoteAction
    };
};

export default SendFormService;
