import { useState } from "react";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slide from "../Slide/Slide";

import {videos_array} from "../../const";
import CustomSlide from "../SlideCustom/Slide";

const SliderSlick = ({ data }) => {
  const [status, setStatus] = useState(false);

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          // arrows: false,
          centerMode: true,
          centerPadding: "250px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          // arrows: false,
          centerMode: true,
          centerPadding: "180px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 870,
        settings: {
          // arrows: false,
          centerMode: true,
          centerPadding: "120px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          // arrows: false,
          centerMode: true,
          centerPadding: "80px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 470,
        settings: {
          // arrows: false,
          centerMode: true,
          centerPadding: "60px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          // arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };


  return (
    <Slider
      beforeChange={(currentSlide, nextSlide) => {
        setStatus(null);
        let allSlides = document.querySelectorAll(".slick-slide");
        let nextIndex = currentSlide + 1; // assume moving right
        if (
          currentSlide - 1 === nextSlide ||
          (nextSlide + 1 === allSlides.length && currentSlide < nextSlide)
        ) {
          nextIndex = currentSlide - 1; // nope, moving left
        }
        // console.log(document.querySelector(`[data-index="${nextIndex}"]`));
        document
          .querySelector(`[data-index="${nextIndex}"]`)
          .classList.add("slick-current-end");
      }}
      afterChange={(nextSlide) => {
        setStatus(false);
        document
          .querySelector(".slick-slide")
          .classList.remove("slick-current-end");
      }}
      {...settings}
    >
      {/*{data.map((item, i) => (*/}
      {/*  <Slide data={item} key={i} status={status} />*/}
      {/*))}*/}
      {videos_array.map((item, i) => (
          <CustomSlide data={item} key={i} status={status} />
      ))}


    </Slider>
  );
};

export default SliderSlick;
