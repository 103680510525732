import {useEffect, useRef, useState} from "react";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import Button from "../Button/Button";

const CustomSlide = ({data: {videoUrl, car, capacity, years, transmission, assignment}, status}) => {
    const [showImg, setShowImg] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [showInfo, setShowInfo] = useState(false);

    useEffect(() => {
        if (status === false) {
            setShowVideo(false);
            setShowInfo(false);
        }
    }, [status]);

    const handleShowVideo = () => {
        setShowInfo(false);
        setShowVideo(true);
    };

    const handleShowInfo = () => {
        setShowVideo(false);
        setShowInfo(!showInfo);
    };


    return (
        <div className="slide w-full max-w-[532px] h-full flex flex-col items-center">
            <div
                className={` slide-top transition-all duration-500 relative overflow-hidden custom-slide-top`}
            >
                <Plyr
                    source={{

                        type: "video",
                        sources: [
                            {
                                src: videoUrl,
                                type: "video/mp4",
                            },
                        ],
                    }}
                    options={{
                        clickToPlay: true,
                        controls: ["play-large", "play", "progress", "volume", "fullscreen"],

                    }}
                />
            </div>
            <div
                className={`${
                    showImg ? "max-h-0 slide-bottom-not-active" : "max-h-[1000px] "
                }  overflow-hidden slide-bottom transition-all duration-500 review-info w-full flex flex-col items-center`}
            >
                <h3 className="slide-title text-3xl text-white my-5">
                    ВИКОНАНЕ ЗАМОВЛЕННЯ
                </h3>
                <ul
                    className={`w-full m-3 reviews-slide-list ${
                        showInfo
                            ? "max-h-[1000px] transition-all duration-300 overflow-hidden mb-10"
                            : "max-h-0 transition-all duration-300 overflow-hidden"
                    }`}
                >
                    <li className="flex reviews-slide-item mb-2 justify-between">
            <span className="text-3xl leading-none reviews-slide-questin text-white">
              Авто
            </span>
                        <span className="text-[27px] flex items-end leading-none reviews-slide-answer uppercase text-end text-white">
              {car}
            </span>
                    </li>
                    <li className="flex reviews-slide-item mb-2 justify-between">
            <span className="text-3xl leading-none reviews-slide-questin text-white">
              Об'єм двигуна
            </span>
                        <span
                            className="text-[27px] leading-none flex items-end reviews-slide-answer text-end uppercase text-white">
              {capacity}
            </span>
                    </li>
                    <li className="flex reviews-slide-item mb-2 justify-between">
            <span className="text-3xl leading-none reviews-slide-questin text-white">
              Рік авто
            </span>
                        <span
                            className="text-[27px] leading-none flex items-end reviews-slide-answer text-end uppercase text-white">
              {years}
            </span>
                    </li>
                    <li className="flex reviews-slide-item mb-2 justify-between">
            <span className="text-3xl leading-none reviews-slide-questin text-white">
              Коробка передач
            </span>
                        <span className="text-[27px] leading-none flex items-end reviews-slide-answer text-end uppercase text-white">
              {transmission}
            </span>
                    </li>
                    <li className="flex reviews-slide-item  justify-between">
            <span className="text-3xl leading-none reviews-slide-questin text-white">
              Деталі
            </span>
                        <span
                            className="text-[27px] leading-none flex items-end reviews-slide-answer text-end uppercase text-white">
              {assignment}
            </span>
                    </li>
                </ul>
                <div
                    className={`${
                        showImg
                            ? "absolute  bottom-4 left-1/2 -translate-x-1/2"
                            : "relative"
                    } slide-button`}
                >
                    <Button
                        onClick={() => handleShowInfo()}
                        text={showInfo ? "Назад" : "Інформація"}
                        classesButton={"slide-button"}
                        classesDiv={"slide-button"}
                    />
                </div>
            </div>
        </div>
    );
};

export default CustomSlide;
